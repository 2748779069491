:root{
  --border-radius-button: 4vw;
  --button-width-mobile: 25%;
  --button-fonts: Fira;
}
html{
  background: radial-gradient(circle at 50%, darkgoldenrod 30%, purple 130%) ;
  cursor:default;
  background-size: contain;
  font-weight: normal;
}
body {
  font: 14px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue","Erica One"
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
}
h1{
  font-weight: normal;
}

@media screen and (max-aspect-ratio: 3/2){
  body .title{
    font-size: 15vw;
    /* transform: scalex(5); */
  }
  body{
    font-family: 'Times New Roman', Times, serif;
  }
  
  body .game{
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
  }
  body .question-active{
    top: 0;
    left: 0;
    }
  body #categories button{
    font-size: 2.8vw;
    width: 16.7%;
    overflow-wrap: break-word;
    word-wrap: break-word;
    hyphens: auto;
    -webkit-hyphens: auto;
  }
  body #form{
    width: 60%;
    box-sizing: content-box;
  }
  body #form>#name{
    width: 80%;
    margin: 5%;
    font-size: xxx-large;
    box-sizing: content-box;
  }
  body .play, body #form>#submit{
    font-size: 3vh;
    width: 30%;
    box-sizing: content-box;
    height: min-intrinsic;
  }
  body h2{
    font-size: 2vw;
  }
  
}

@font-face{
  font-family: "Erica One";
  src: url('https://fonts.gstatic.com/s/ericaone/v10/WBLnrEXccV9VGrOKmGDFXEXN.woff2') format('woff2');
  /*TODO add more font mime types */
  font-style: normal;
}
h2{
  font-size: 2vh;
  font-weight: 400;
  position: absolute;
  text-align: center;
  bottom: 1%;
  left: 3%;
  color: ivory;
}
.nowifi{
  display: block;
  margin: auto;
  height: 25%;
  width: 25%;
  fill: rgb(206, 48, 28);
}
#error{
  width: fit-content;
  margin: auto;
  padding: 3px 15px;
  border-radius: 35px;
  border: solid red 5px;
  color: black;
  font-weight: bold;
  box-shadow: 0px 0px 10px 10px indianred;
  background-color: whitesmoke;
  text-align: center;
  
}
#top-header{
  display: block;
  width: 95%;
  height:10%;
  position: fixed;
  top: 0;
  left: 2.5%;
  background: repeating-linear-gradient(to top,brown 65%, transparent),repeating-linear-gradient(aliceblue 20px,gold 5px, red 45px);
  border-style: ridge;
  border-radius: 10px;
}
#left-post{
  display: inline-flex;
  position: absolute;
  top: 10%;
  left: 5%;
  height: 90%;
  width: 5%;
  background: repeating-linear-gradient(#894343 31px, goldenrod 78px);
}
#right-post{
  display: inline-flex;
  position: absolute;
  top: 10%;
  right: 5%;
  height: 90%;
  width: 5%;
  background: repeating-linear-gradient(#894343 31px, goldenrod 78px);
}

.title{
  display: block;
  position: relative;
  line-height: 0;
  top: 30vh;
  margin: 0;
  font-family: "Erica One";
  color: rgb(250, 212, 4);
  font-size: 14vw;
  text-align: center;
  text-shadow: 5px 8px #64444C;
  transform-origin: top;
  transform: scaleY(3);
  -webkit-transpodium: scaleY(3);

}
/* TODO create animation for button */
.play{
  display: block;
  position: absolute;
  bottom: 15%;
  left: 0;
  right: 0;
  margin: auto;
  border-radius: var(--border-radius-button);
  font-family: var(--button-fonts);
  font-size: 2rem;
  width: 10vw;
  height: fit-content;
  padding-top: .5vh;
  padding-bottom: .5vh;
  min-height: min-content;
  cursor: pointer;
  background-color: rgb(39, 57, 59);
  color: rgb(251, 255, 0);
}
#round{
  color: black;
  position: fixed;
  padding-left: 3px;
  padding-right: 3px;
  bottom: 4%;
  z-index: 1;
  right: 15%;
  font-size: 4vh;
  width: fit-content;
  height: 5%;
  border: 1px solid black;
  border-radius: 5px;
  background: whitesmoke;
}
#restart {
  display: block;
  position: fixed;
  left: 15%;
  bottom: 4%;
  z-index: 1;
  border-radius: 20px;
  width: 3%;
  height: 5%;
  cursor: pointer;
  background-color: rgb(215, 225, 236);
  color: rgb(251, 255, 0);
  box-sizing: content-box;
}
.restart-svg{
  height: 100%;
  width: 100%;  
}
.podium{
  display: grid;
  margin: auto;
  height: 100%;
  width: 100%;
  grid-area: podium;
}
#timer{
  -webkit-appearance: none;
  display: block;
  background-color: rgb(128, 128, 128);
  height: 3px;
  width: 100%;
}
#timer::-webkit-progress-value{
  background-color: red;
}
 #timer::-moz-progress-bar{
  background-color: red;
}
#timer::-webkit-progress-inner-element{
  background-color: rgba(128, 128, 128, 0.774);
}

#timer::-webkit-progress-bar{
  background-color: linear-gradient(gray, );
}
#player{
  background: dodgerblue;
  margin: 0;
  text-align: center;
  width: 100%;
  color: white;
  font-size: 5vh;
  line-height: 2;
}

.askName-active{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
  background: #2b1f1f61;
  text-align: center;
  color: black;
  font-size: 5vh;
  height: 100%;
  width: 95%;
}
#form{
  height: 20%;
  position: relative;
  margin: auto;
  top: 50%;
  border-radius: 40px;
  width: 40%;
  background: white;
  padding-top: 20px;
  box-sizing: content-box;
}

#loading{
  display: block;
  position: absolute;
  margin: auto;
  top: 50%;
  left: 0;
  right: 0;
  width: 35%;
  height: fit-content;
  text-align: center;
  color: whitesmoke;
  background-color: rgb(123, 191, 218);
  font-size: xx-large;
}

#final-score{
  display: block;
  margin: auto;
  height: intrinsic;
  height: fit-content;
  width: 25%;
  text-align: center;
  font-size: 5em;
  top: 0;
  font: bolder;
}
#final-score > p{
  color: gold;
}
#final-score > button{
  border-radius: var(--border-radius-button);
  width: 10vw;
  height: 5vh;
  cursor: pointer;
  background-color: rgb(39, 57, 59);
  color: rgb(251, 255, 0);
}

dialog::backdrop{
  background: #2b1f1f61;
}

#form > #name{
  text-align: center;
  border-radius: var(--border-radius-button);
  height: 5vh;
  width: 20vw;
  font-size: x-large;
  border-color: black;
  background-color: whitesmoke;
}
#form > #submit , #error > button{
  display: block;
  border-radius: var(--border-radius-button);
  height: fit-content;
  padding-bottom: .5vh;
  padding-top: .5vh;
  width: 30%;
  font: 2rem var(--button-fonts);
  cursor: pointer;
  margin: auto;
  color: rgb(251, 255, 0);
  border: black;
  background: rgb(39, 57, 59);
  margin-top: 15px;
}


#score{
  background-color: #281dbf;
  color: white;
  font-size: 3vh;
  margin: 0;
  text-align: center;
  line-height: 2;
}

.columns{
  display: inline;
  clear: both;
}
.game button{
  background: rgb(60, 102, 218);
  border: 1px solid black;
  color: goldenrod;
  box-sizing: border-box;
  font-size: 3.5vw;
  height: inherit;
  width: inherit;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
}

#categories button{
  display: block;
  color: antiquewhite;
  font-size: 1.8vw;
  height: 100%;
  padding: 0;
}

#categories div {
  display: inline-flex;
  width: 100%;
  height: 100%;
}

#categories{
  grid-area: categories;
  height: 100%;
}
#amount{
  display: flex;
  grid-area: amount;
  height: 100%;
}

#answer{
  margin-left: 25%;
  margin-top: 5%;
  width: 50%;
  font-size: 5vh;
  text-align: center;
}

.answer-background{
  background: dodgerblue;
  grid-area: podium;
}

#amount>div{
  display: inline-flex;
  flex-direction: column;
  width: 100%;
  height: 100%
}
#amount button:disabled{
  content: none;
  cursor: unset;
}
.game{
  display: grid;
  grid-auto-rows:  1fr 3fr 1fr;
  grid-template-areas: " categories categories categories categories categories categories "
                       " amount amount amount amount amount amount "
                       " podium podium podium podium podium podium ";
  top: 10%;
  left: 10%;
  position: fixed;
  width: 80%;
  height: 90%;
}

.question-active h1{
  position: sticky;
  top: 20px;
  margin: auto;
  
}

.question-active{
  font-size: 2vw;
  height: 80%;
  width: 100%;
  position: absolute;
  /* height: 72%;
  position: fixed;
  top: 10%;
  left: 10%; */
  color: antiquewhite;
  border-color: black;
  background-color: rgb(60, 102, 218);;
  text-align: center;
  display: flex;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
